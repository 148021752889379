import React from 'react'
import Galleries from '../../components/galleries/galleries.js'
import Layout from '../../layouts/index.js'

const array = [
  '007163',
  '007169',
  '007178',
  '007181',
  '007187',
  '007197',
  '007207',
  '007216',
  '007218',
  '007221',
  '007229',
  '007232',
  '007264',
]

const images = array.map((image) => {
  return (
    <figure>
      <img
        alt=""
        key={image}
        src={require('../../images/gallery/madagascar/' + image + '.jpg')}
      />
    </figure>
  )
})

const Madagascar = () => (
  <Layout>
    <section>
      <h1>Madagascar</h1>
      {images}
      <Galleries />
    </section>
  </Layout>
)

export default Madagascar
